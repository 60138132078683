:root {
  font-size: 60%;

  --color-black: #000000;
  --color-white: #ffffff;
  --color-primary: #ffea36;
  --color-primary-dark: #c7b836;
  --color-background: #111312;
  --color-form-background: #16191f;
  --color-form-border: #444444;
  --color-input-background: #f5f5f5;
  --color-input-border: #333333;
  --color-hyperlink: #4d95f3;
  --color-red: #e34646;
  --color-green: #7dbe31;
}

* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  font: 400 1.6rem Maven Pro;
  background: var(--color-background);
}

button {
  cursor: pointer;
}

@media (min-width: 900px) {
  :root {
    font-size: 62.5%;
  }
}
